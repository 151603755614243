import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight, faExclamationTriangle, faHome } from "@fortawesome/free-solid-svg-icons";
import HtmlType from "@/components/FieldTypes/HtmlType.vue";
import CommonSelect from "@/components/commons/CommonSelect.vue";
import { localizationService } from "@/main";
import ButtonType from "@/components/FieldTypes/ButtonType.vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import { VueRecaptchaWrapper } from "@/components/VueRecaptchaComponents";
import CommonIcon from "@/components/commons/CommonIcon.vue";
import FurtherQuestionsType from "@/components/FieldTypes/FurtherQuestionsType.vue";
import TermsConditionsType from "@/components/FieldTypes/TermsConditions.vue";
import PropertyType from "@/components/FieldTypes/PropertyType.vue";
import { TagTypeEnum } from "@/enums";
import CommonAlert from "@/components/commons/CommonAlert.vue";
library.add(faHome);
library.add(faChevronRight);
library.add(faExclamationTriangle);
export default Vue.extend({
    components: {
        CommonAlert,
        TermsConditionsType,
        FurtherQuestionsType,
        CommonIcon, VueRecaptchaWrapper, CommonButton, ButtonType, CommonSelect, PropertyType, HtmlType
    },
    data() {
        return {
            localizationService,
            noResultText: '',
            collapsedTags: [],
            oldStep: {},
            newStep: {},
            doneStepsClone: [],
            saveIndex: undefined
        };
    },
    mounted() {
    },
    props: {
        doneSteps: {
            type: Array,
            default: []
        },
        breadcrumbs: {
            type: Array,
            default: []
        },
        isTabButton: {
            type: Boolean,
            default: false
        },
        submitBtnVariant: {
            type: String,
            default: 'success'
        },
        surveyCanGoNext: Boolean,
        surveyEntryList: Array,
        btnDetails: {},
        formFields: {},
        termsConditionsOkBtnVariant: String,
        tokenType: Number,
        customerCode: String,
        searchQuery: String,
        templateName: String,
        isSurvey: Boolean,
        isFormReadOnly: Boolean,
        isThereMandatoryField: Boolean,
        userInfos: Object,
    },
    methods: {
        handlePropertyIn(arg) {
            this.$emit("propertyIn", arg);
        },
        handlePropertyDestroyed(arg) {
            this.$emit("propertyDestroyed", arg);
        },
        handlePropertyStateChanged(valid, pt) {
            this.$emit("propertyStateChanged", valid, pt);
        },
        handleOnSubmitted(callBack) {
            this.$emit("onSubmitted", callBack);
        },
        handleAnsweredFurtherQuestions(fields, index) {
            this.$emit("answeredFurtherQuestions", fields, index);
        },
        handleValidateProps() {
            this.$emit("validateProps");
        },
        handleSelectNode(selected, index, newStep) {
            this.$emit("selectNode", selected, index, newStep);
        },
        handleGoToNextQuestion(index, selectedAnswer) {
            this.$emit("goToNextQuestion", index, selectedAnswer);
        },
        openModal(selected, index, newStep) {
            const realIndex = this.doneSteps.findIndex(val => val.id === selected.id);
            if (index === this.doneStepsClone.length - 1) {
                this.handleSelectNode(selected, realIndex, newStep);
            }
            else {
                this.oldStep = selected;
                this.newStep = newStep;
                this.saveIndex = realIndex;
                this.$bvModal.show("change-option");
                console.log("Selected: ", selected);
                console.log("Index: ", realIndex);
            }
        },
        changeCollapse(tag) {
            if (this.collapsedTags.includes(tag)) {
                this.collapsedTags = this.collapsedTags.filter(ctag => ctag !== tag);
            }
            else {
                this.collapsedTags.push(tag);
            }
        },
        disabledList(step) {
            if (this.searchQuery == null || this.searchQuery == "") {
                return step.disabledOptions;
            }
            let res = step.disabledOptions.filter((option) => option.label.toLowerCase().includes(this.searchQuery.toLowerCase()));
            if (res.length == 0) {
                this.noResultText = this.localizationService.FormLoc('no_risultato');
            }
            else {
                this.noResultText = "";
            }
            return res;
        },
    },
    watch: {
        doneSteps: {
            handler() {
                this.doneStepsClone = this.doneSteps.filter((ds) => ds.form_fields.filter((ff) => ff.type !== "HTML_HEADER").length > 0 || ds.children.filter((ch) => ch.tag_type !== TagTypeEnum.FORM_LANG).length > 0);
            },
            deep: true
        }
    }
});
