import Vue from 'vue';
import CommonButton from "@/components/commons/CommonButton.vue";
import { localizationService } from "@/main";
export default Vue.extend({
    components: { CommonButton },
    props: {
        resultsSet: {
            type: Array
        },
        templateName: String
    },
    data() {
        return {
            localizationService,
            page: 1,
            perPage: 3,
            pageTemp: 1,
            optionsPerPage: [
                { value: 3, text: "3" },
                { value: 5, text: "5" },
                { value: 10, text: "10" },
                { value: 20, text: "20" },
                { value: 500, text: "Tutti" }
            ],
            resultsSetFiltered: undefined
        };
    },
    computed: {
        maxPages() {
            return Math.ceil(this.resultsSet?.length / this.perPage);
        },
    },
    beforeDestroy() {
        this.$emit("resultsSetFiltered", []);
    },
    methods: {
        localizationService() {
            return localizationService;
        },
        updateResultsFiltered() {
            this.$emit("resultsSetFiltered", this.resultsSet?.filter((_element, index) => {
                return (index < this.page * this.perPage &&
                    index > (this.page - 1) * this.perPage - 1);
            }));
        },
        handleNavigationClick(page, relative) {
            if (isNaN(page)) {
                this.pageTemp = this.page;
                return;
            }
            var tempPage = this.page;
            if (relative) {
                tempPage += Number(page);
            }
            else {
                tempPage = page;
            }
            if (tempPage > this.maxPages) {
                tempPage = this.maxPages;
            }
            else if (tempPage < 1) {
                tempPage = 1;
            }
            this.pageTemp = tempPage;
            this.page = tempPage;
            return;
        },
    },
    mounted() {
        this.updateResultsFiltered();
    },
    watch: {
        resultsSet() {
            this.updateResultsFiltered();
        },
        page() {
            this.updateResultsFiltered();
        },
        perPage() {
            this.updateResultsFiltered();
        }
    }
});
