import Vue from "vue";
import homeMixin from '@/mixins/homeMixin';
import { AuthType } from "@/enums";
import SelectLocale from "@/components/SelectLocale.vue";
import LangIcon from "@/components/LangIcon.vue";
import ChooseLanguage from "@/components/ChooseLanguage.vue";
export default Vue.extend({
    mixins: [homeMixin],
    components: {
        ChooseLanguage,
        SelectLocale,
        LangIcon
    },
    props: {
        templateName: String
    },
    data() {
        return {
            language: "",
            AuthType: AuthType
        };
    },
    methods: {
        setLanguage(language) {
            this.$refs.smartForm.setLanguage(language, false);
        }
    }
});
