import Vue from "vue";
import { AuthType, FormFieldTypeCodeEnum, ReadOnlyType, TagTypeEnum } from "@/enums";
import { defaultLanguage, globalEventBus, localizationService, webRestService } from "@/main";
import { SSO_KEY } from "@/constants";
import swal from "sweetalert2";
import { isASurvey, validateEmail } from '@/utils/util';
import { mapGetters } from "vuex";
import SelectLocale from "@/components/SelectLocale.vue";
import HtmlType from "@/components/FieldTypes/HtmlType.vue";
import PropertyType from "@/components/FieldTypes/PropertyType.vue";
import FurtherQuestionsType from "@/components/FieldTypes/FurtherQuestionsType.vue";
import TermsConditionsType from "@/components/FieldTypes/TermsConditions.vue";
import ButtonType from "@/components/FieldTypes/ButtonType.vue";
import { VueRecaptchaWrapper } from "@/components/VueRecaptchaComponents";
import Search from "@/components/Search.vue";
import CrispLiveChat from "@/components/CrispLiveChat.vue";
import HtmlHeaderType from "@/components/FieldTypes/HtmlHeaderType.vue";
import smartFormHeaderMixin from "@/mixins/smartFormHeaderMixin";
import CommonCard from "@/components/commons/CommonCard.vue";
import CommonSelect from "@/components/commons/CommonSelect.vue";
import { cloneDeep } from "lodash";
import WizardSection from "@/components/WizardSection.vue";
import CommonButton from "@/components/commons/CommonButton.vue";
import SmartformFooter from "@/components/SmartformFooter.vue";
import EbbyLiveChat from "@/components/EbbyLiveChat.vue";
import TimelineBreadcrumbs from "@/components/Welcome/TimelineBreadcrumbs.vue";
function initializeSendObject() {
    return {
        email: undefined,
        confirmed_email: undefined
    };
}
export default Vue.extend({
    components: {
        TimelineBreadcrumbs,
        SmartformFooter,
        CommonButton,
        WizardSection,
        EbbyLiveChat,
        CrispLiveChat,
        SelectLocale,
        HtmlType,
        PropertyType,
        FurtherQuestionsType,
        TermsConditionsType,
        ButtonType,
        VueRecaptchaWrapper,
        Search,
        HtmlHeaderType,
        CommonCard,
        CommonSelect
    },
    mixins: [
        smartFormHeaderMixin
    ],
    computed: {
        ...mapGetters('auth', {
            getIsOtpCodeToSend: "getIsOtpCodeToSend"
        }),
        isSurvey() {
            return isASurvey(this.authType);
        },
        surveyCanGoNext() {
            if (this.isSurvey) {
                for (const isValidAnswer of Object.values(this.formPropertyValidation)) {
                    if (!isValidAnswer) {
                        return false;
                    }
                }
            }
            return true;
        },
        surveyCanGoPrevious() {
            if (this.isSurvey) {
                return this.doneSteps.length > 1;
            }
            return true;
        },
        isSendButtonDisabled() {
            return this.isEmailInvalid() || this.checkCaptcha;
        },
        checkCaptcha() {
            return this.showCaptcha && !this.captchaResolved;
        },
        otpCode() {
            if (this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE]) {
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
            else {
                let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${this.customerCode}`;
                let otp = this.$cookies.get(cookieName);
                if (otp) {
                    this.$store.dispatch(SSO_KEY.SET_STORE_OTP_CODE, atob(otp));
                }
                return this.$store.getters[SSO_KEY.GET_STORE_OTP_CODE];
            }
        },
        browserLanguage() {
            return navigator.language;
        },
        codeTag() {
            return this.$route.params.codeTag;
        },
    },
    data() {
        return {
            cardBodyId: 'smartform-',
            surveyEntryList: null,
            surveyCodeTag: null,
            surveySent: false,
            ticketCode: "",
            otpResponseCode: "",
            body: {},
            wizardBreadcrumb: [],
            token: "",
            searchQuery: "",
            sendObject: initializeSendObject(),
            captchaResolved: false,
            formPropertyValidation: {},
            formPropertyReadOnly: {},
            formPropertyMandatory: {},
            formFields: {},
            languageLogoPath: '',
            languageFlags: {},
            localizationService,
            doneSteps: [],
            doneStepsSearch: [],
            isFormSent: false,
            showCaptcha: false,
            showLanguageModal: false,
            sentWithCode: false,
            noResultText: '',
            searchStr: null,
            languages: [],
            languagesDescription: [],
            tagsPath: [],
            languageForm: {},
            confirmModalStep: undefined,
            furtherQuestions: {},
            notifierEmail: "",
            summaryHtml: "",
            isFreeCaptcha: false,
            AuthType,
            ReadOnlyType,
            currentLanguage: '',
            sendBtnCallback: 'defaultSendBehaviour'
        };
    },
    mounted() {
        this.$emit('isSendButtonDisabled', this.isSendButtonDisabled);
        this.cardBodyId += Math.floor(Math.random() * (100 - 1 + 1)) + 1;
        globalEventBus.$on('reload-form', () => {
            this.reloadForm();
        });
        globalEventBus.$on('change-locale', (language) => {
            this.setLanguage(language, true);
        });
        this.noResultText = localizationService.FormLoc('no_risultato');
        const sessionData = sessionStorage.getItem('searchData');
        if (sessionData) {
            this.searchStr = JSON.parse(sessionData).search;
        }
        sessionStorage.removeItem('searchData');
    },
    props: {
        sitekey: {
            type: String
        },
        customerLogo: {
            type: String
        },
        error: {
            type: Boolean
        },
        customerCode: {
            type: String
        },
        tokenExpiration: {
            type: String
        },
        datacollection: {
            type: Boolean,
            default: false
        },
        footerSticky: {
            type: Boolean,
            default: false
        },
        layoutDefault: {
            type: Boolean,
            default: false
        },
        userInfos: {
            type: Object
        },
        authType: {
            type: Array,
            default: []
        },
        tokenType: {
            type: Number
        },
        answerStyle: {
            type: String,
            default: ''
        },
        showHeader: {
            type: Boolean,
            default: false
        },
        submitBtnVariant: {
            type: String,
            default: 'success'
        },
        termsConditionsOkBtnVariant: {
            type: String,
            default: undefined
        },
        isTabButton: {
            type: Boolean,
            default: false
        },
        formCollapsible: {
            type: Boolean,
            default: true
        },
        formFlat: {
            type: Boolean,
            default: false
        },
        noFormOverflow: {
            type: Boolean,
            default: false
        },
        templateName: String,
        templateConfig: {}
    },
    created() {
        window.addEventListener("beforeunload", this.beforeunload);
    },
    methods: {
        validateProps() {
            globalEventBus.$emit("validatePlease");
        },
        getFormFieldValue(field) {
            let theField = this.lodash.filter(this.formFields, (ff, ind) => ind.split("---")[0] === field);
            return theField[0] || null;
        },
        isFieldReadOnly(field) {
            return (field?.readonly && field.readonly === this.ReadOnlyType.LOCKED)
                || (field.readonly === this.ReadOnlyType.UNLOCKED
                    && field.mapping?.trim() != "" && this.userInfos
                    && this.userInfos[field.mapping] != undefined
                    && this.userInfos[field.mapping]?.toString().trim() !== "");
        },
        isEmailInvalid() {
            let tmpMail = this.sendObject.email || (this.userInfos &&
                this.userInfos.email &&
                this.userInfos.email.length == 1
                ? this.userInfos.email[0]
                : "");
            return !tmpMail || !validateEmail(tmpMail);
        },
        isFormReadOnly() {
            var isReadOnly = true;
            this.lodash.forEach(this.formPropertyReadOnly, (ro) => {
                isReadOnly = isReadOnly && ro;
            });
            return isReadOnly;
        },
        isThereMandatoryField() {
            var isMandatory = false;
            this.lodash.forEach(this.formPropertyMandatory, (pm) => {
                isMandatory = isMandatory || pm;
            });
            return isMandatory;
        },
        okCaptchaModal: function () {
            if (this.body) {
                this.sendConfirmationEmailStep(this.body, this.sendBtnCallback);
                this.body = {};
                this.showCaptcha = false;
            }
        },
        onVerify: function (response) {
            if (response) {
                this.token = response;
                this.captchaResolved = true;
            }
        },
        getAnswerStyle: function (step) {
            if (step.children.length == 0 && !step.needsHelp) {
                return this.answerStyle;
            }
        },
        scrollToBottom: function () {
            let container = this.$el.querySelector(".main-card .card-body");
            if (container) {
                this.$nextTick(() => container.scrollBy(0, container.scrollHeight));
            }
        },
        showFurtherQuestions(fields, index) {
            let theIndex = this.lodash.findIndex(this.doneSteps, (ds) => ds.needsHelp);
            if (theIndex >= 0) {
                this.doneSteps.splice(theIndex, 99);
            }
            let theStep = this.lodash.cloneDeep(this.lodash.last(this.doneSteps));
            theStep.needsHelp = true;
            theStep.form_fields = [];
            theStep.form_fields.push({
                type: "HTML",
                value: fields.text,
            });
            this.lodash.forEach(fields.fields, (field) => {
                let theField = {};
                theField.keyID = this.lodash.uniqueId();
                theField.type = "PROPERTY";
                theField.value = field.property + "---" + theField.keyID;
                theField.mapping = field.mapping;
                theField.hidden = field.hidden;
                theField.required = field.required;
                theField.readonly = field.readonly;
                this.formFields[theField.value] = undefined;
                Vue.set(this.formPropertyValidation, theField.value, false);
                this.formPropertyReadOnly[theField.value] = this.isFieldReadOnly(theField);
                this.formPropertyMandatory[theField.value] = theField.required;
                theStep.form_fields.push(theField);
            });
            this.furtherQuestions[fields.id] = fields.whatSelected;
            this.doneSteps.push(theStep);
            this.doneStepsSearch = cloneDeep(this.doneSteps);
            this.scrollToBottom();
        },
        onPropertyStateChanged(valid, pt) {
            Vue.set(this.formPropertyValidation, pt, valid);
        },
        onPropertyDestroyed(arg) {
            let attachment = 'attachments-' + arg.value;
            Vue.delete(this.formFields, arg.value);
            Vue.delete(this.formPropertyValidation, arg.value);
            Vue.delete(this.formPropertyReadOnly, arg.value);
            Vue.delete(this.formPropertyMandatory, arg.value);
            let arrSendObj = Object.keys(this.sendObject);
            let sendObject = this.sendObject;
            this.lodash.forEach(arrSendObj, function (so) {
                if (so.includes(attachment)) {
                    Vue.delete(sendObject, so);
                }
            });
            //Vue.delete(this.sendObject, "attachments-file-documentazione---6-8");
        },
        onPropertyIn(arg) {
            switch (true) {
                case ["file"].includes(arg.input_type):
                    if (!arg.size_exceeded) {
                        this.lodash.forEach(Object.keys(this.sendObject), (v) => {
                            if (v.includes(arg.code)) {
                                delete this.sendObject[v];
                            }
                        });
                        if (Array.isArray(arg.value)) {
                            this.lodash.forEach(arg.value, (v, k) => this.sendObject["attachments-" + arg.code + "-" + this.lodash.uniqueId()] = arg.value[k]);
                        }
                        else {
                            this.sendObject["attachments-" + arg.code] = arg.value;
                        }
                    }
                    break;
                case ["select_data_source"].includes(arg.input_type):
                    let formKeys = Object.keys(this.formFields);
                    if (formKeys.length) {
                        let codiceProp = arg.code.split("---")[0];
                        let selectDataSourceProperty = {};
                        this.doneSteps.forEach((doneStep) => {
                            doneStep.properties.forEach((property) => {
                                if (property.property_code === codiceProp) {
                                    selectDataSourceProperty = property;
                                }
                            });
                        });
                        let propertyValue = '';
                        selectDataSourceProperty.default_value.payloads.forEach((payload) => {
                            if (payload.type === 'property') {
                                propertyValue = payload.value;
                            }
                        });
                        let valueToSwap = '';
                        if (propertyValue.trim().length) {
                            formKeys.forEach((formKey) => {
                                let key = formKey.split("---")[0];
                                if (propertyValue.trim() === key && this.formFields[formKey]) {
                                    valueToSwap = this.formFields[formKey];
                                }
                            });
                        }
                        if (valueToSwap.trim().length) {
                            let restPayload = {};
                            restPayload.action = selectDataSourceProperty.default_value.service_code;
                            selectDataSourceProperty.default_value.payloads.forEach((payload) => {
                                restPayload[payload.key] = valueToSwap;
                            });
                            webRestService.callDataSource(this.customerCode, "strutture-sanitarie", restPayload).then(function (data) {
                                globalEventBus.$emit('restSelectDataSourcePayloads', {
                                    "selectID": selectDataSourceProperty.property_code,
                                    "options": data
                                });
                            });
                        }
                        this.formFields[arg.code] = arg.value;
                    }
                    break;
                case ["select"].includes(arg.input_type):
                    if (arg.value.value) {
                        this.formFields[arg.code] = arg.value.value;
                    }
                    else {
                        this.formFields[arg.code] = arg.value;
                    }
                    break;
                case ["date"].includes(arg.input_type):
                    const date = new Date(arg.value);
                    const hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;
                    date.setHours(hoursDiff);
                    this.formFields[arg.code] = date;
                    break;
                case ["multi_checkbox"].includes(arg.input_type):
                    let valoriMulticheck = [];
                    Object.keys(arg.value).forEach((k) => valoriMulticheck.push({
                        "key": k,
                        "state": arg.value[k]
                    }));
                    this.formFields[arg.code] = valoriMulticheck;
                    break;
                default:
                    this.formFields[arg.code] = arg.value;
                    break;
            }
            if (arg.input_type === "codfis" && !!arg.value && arg.value.length === 16 && !!this.lodash.find(this.doneSteps, (ds) => this.lodash.find(ds.properties, (p) => p.default_value?.input_type == 'codfis'))) {
                globalEventBus.$emit("validatePlease", true);
            }
        },
        onModalSubmit() {
            if (this.sendObject.email && validateEmail(this.sendObject.email)) {
                this.sendConfirmationEmail(this.sendBtnCallback);
            }
            else {
                swal
                    .fire(this.localizationService.FormLoc('errore'), this.localizationService.FormLoc('inserire_email_valida'), "error")
                    .then(() => {
                    this.$bvModal.show("modale-inserimento-indirizzo");
                    this.captchaResolved = false;
                });
            }
        },
        sendConfirmationEmail(callBack) {
            callBack = callBack || this.sendBtnCallback;
            this.sendObject.email = this.sendObject.email || (this.userInfos &&
                this.userInfos.email &&
                this.userInfos.email.length > 0
                ? this.userInfos.email
                : this.sendObject.email);
            if (!this.sendObject.confirmed_email) {
                this.sendObject.confirmed_email = this.sendObject.email; //anche in caso di SSO manda confirmed_email perché potrebbero essere casi di form free/free captcha e sso
            }
            this.sendObject.further_questions = JSON.stringify(this.furtherQuestions);
            Object.keys(this.formFields).forEach(key => {
                if (this.formFields[key] === undefined) {
                    this.formFields[key] = "";
                }
            });
            this.sendObject.form_fields = this.lodash.cloneDeep(this.formFields);
            if (this.otpCode) {
                this.$store.dispatch('auth/setIsOtpToSend');
            }
            // If it is a survey, send the "root tag"
            this.sendObject.code_tag = this.isSurvey
                ? this.surveyCodeTag
                : this.doneSteps[this.doneSteps.length - 1].code_tag;
            if (this.showCaptcha) {
                this.sendObject.captcha_resolved = this.captchaResolved;
            }
            if (this.isSurvey) {
                this.sendObject.email = "survey-smartemail@previmedical.it";
            }
            //const body = JSON.stringify(this.sendObject);
            const body = new FormData();
            this.lodash.forEach(this.formFields, (ff, key) => {
                this.sendObject.form_fields[key.split("---")[0]] = ff;
                Vue.delete(this.sendObject.form_fields, key);
            });
            //se otp metti otp
            if (this.getIsOtpCodeToSend) {
                this.sendObject.otp_code = this.otpCode;
            }
            this.lodash.forOwn(this.sendObject, function (value, key) {
                if (key == "form_fields") {
                    value = JSON.stringify(value);
                }
                body.append(key, value);
            });
            this.sendConfirmationEmailStep(body, callBack);
        },
        defaultSendBehaviour() {
            const messageNodes = this.$createElement("p", {
                domProps: {
                    innerHTML: this.confirmModalStep && this.confirmModalStep.value ?
                        this.confirmModalStep.value.replaceAll('{{from}}', this.sendObject.email).replaceAll('{{to}}', this.notifierEmail).replaceAll('{{expiration}}', this.tokenExpiration) :
                        this.localizationService.FormLoc('controlla_casella') +
                            this.sendObject.email +
                            this.localizationService.FormLoc('clicca_link_email::' + this.notifierEmail + "::" + this.tokenExpiration),
                },
            });
            this.$bvModal
                .msgBoxOk([messageNodes], {
                title: this.localizationService.FormLoc('conferma_indirizzo').toString(),
                size: "md",
                buttonSize: "md",
                okVariant: "success",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true,
            })
                .then(() => {
                this.isFormSent = true;
            });
        },
        sendSurvey: function () {
            this.surveySent = true;
        },
        sendConfirmationEmailStep: function (body, callBack) {
            let that = this;
            if (body.has("token")) {
                body.delete("token");
            }
            body.append("token", this.token);
            webRestService
                .sendFormData(body, this.customerCode)
                .then((response) => {
                that.ticketCode = response.ticket;
                if (response.otp) {
                    that.otpResponseCode = response.otp;
                }
                if (response.summary_html) {
                    that.summaryHtml = response.summary_html.trim();
                }
                that.notifierEmail = response.notifier_email;
                if (!that.otpCode || that.isSurvey || (that.userInfos?.email?.length === 0)) {
                    if (!that.datacollection && !that.authType.includes(AuthType.FREE_CAPTCHA)) {
                        that[callBack]();
                    }
                    else {
                        that.isFormSent = true;
                    }
                }
                else {
                    that.isFormSent = true;
                }
                let cookieName = `${SSO_KEY.COOKIE_OTP_CODE}_${that.customerCode}`;
                that.$cookies.remove(cookieName);
                that.$emit('handleTicketCode', response.ticket);
            })
                .catch((error) => {
                let needShowCaptcha = false;
                this.lodash.forEach(error.obj, function (err) {
                    if (err.data.includes("forms0013")) {
                        needShowCaptcha = true;
                    }
                });
                if (needShowCaptcha) {
                    this.body = body;
                    this.showCaptcha = true;
                    this.$bvModal.show("modale-validazione-captcha");
                }
            });
        },
        disabledList(step) {
            if (this.searchQuery == null || this.searchQuery == "") {
                return step.disabledOptions;
            }
            let res = step.disabledOptions.filter((option) => option.label.toLowerCase().includes(this.searchQuery.toLowerCase()));
            if (res.length == 0) {
                this.noResultText = this.localizationService.FormLoc('no_risultato');
            }
            else {
                this.noResultText = "";
            }
            return res;
        },
        onSubmitted(callBack) {
            this.sendBtnCallback = callBack;
            this.captchaResolved = false;
            let isValid = true;
            globalEventBus.$emit("validatePlease", true);
            setTimeout(() => {
                this.lodash.forEach(this.formPropertyValidation, function (fpv, key) {
                    if (!fpv) {
                        console.log("Validazione fallita per: " + key);
                    }
                    isValid = isValid && fpv;
                });
                if (isValid) {
                    if (!this.datacollection && this.otpCode && this.userInfos && this.userInfos.email && this.userInfos.email.length > 0) {
                        if (this.userInfos.email.length == 1) {
                            this.sendObject.email = this.userInfos.email[0];
                        }
                        if (this.isSurvey) {
                            this.sendConfirmationEmail(callBack);
                        }
                        else {
                            this.$bvModal.show("modale-inserimento-sso");
                            this.sentWithCode = true;
                        }
                    }
                    else if (this.authType.includes(AuthType.FREE_CAPTCHA) && !this.otpCode) {
                        this.isFreeCaptcha = true;
                        this.$bvModal.show("modale-inserimento-free-captcha");
                    }
                    else if (!this.authType.includes(AuthType.SURVEY)) {
                        this.$bvModal.show("modale-inserimento-indirizzo");
                    }
                }
                else {
                    swal.fire({
                        title: this.localizationService.FormLoc('errore'),
                        text: this.localizationService.FormLoc('errore_compilazione'),
                        icon: 'error',
                        customClass: { confirmButton: 'btn-primary' }
                    });
                }
            }, 1000);
        },
        scrollToBeginning: function () {
            let container = this.$el.querySelector("#html");
            if (container) {
                this.$nextTick(() => container.scrollIntoView());
            }
        },
        reloadForm() {
            let that = this;
            if (this.otpCode) {
                webRestService
                    .getFormToken(this.customerCode, this.otpCode)
                    .then((response) => that.$router.push("/" + that.customerCode + '/sso' + '?code=' + response));
            }
            else {
                window.location.reload();
            }
        },
        navigateForm(code, index) {
            let that = this;
            webRestService
                .getTagsChildren(code, this.customerCode, this.otpCode)
                .then((response) => {
                ((index) => {
                    if (that.tagsPath.length == 0) {
                        that.tagsPath = response.tags_path;
                        that.tagsPath.push(that.codeTag);
                        that.wizardBreadcrumb = response.breadcrumb;
                        that.wizardBreadcrumb.push(response.description);
                    }
                    if (index < that.tagsPath.length) {
                        if (!that.doneSteps[index]) {
                            that.navigateForm(code, index);
                            return;
                        }
                        that.doneSteps[index].optionSelected = that.lodash.find(that.doneSteps[index].options, (o) => {
                            return o.value.code_tag == that.tagsPath[index];
                        });
                        // that.doneSteps[index].options.forEach((opt: any) => {
                        //   opt.label = opt.label.replaceAll('BONIFICI', '<mark>BONIFICI</mark>')
                        // })
                        const label = that.doneSteps[index].optionSelected.label;
                        if (this.searchStr && label) {
                            const regex = new RegExp(`\\b(${this.searchStr})\\b.*?`, 'gmi');
                            that.doneSteps[index].optionSelected.label = label.replaceAll(regex, (match) => '<mark class="text-mark text-mark-default">' + match + '</mark>');
                        }
                        that.selectNode(that.doneSteps[index], index, undefined, undefined, true);
                        index++;
                        // TODO capire da dove prendere la lingua scelta
                        that.updateHeaderFields(response.form_fields, false, that.currentLanguage);
                        if (that.tagsPath[index]) {
                            setTimeout(() => that.navigateForm(that.tagsPath[index], index), 350);
                        }
                    }
                })(index);
            });
        },
        setLanguage: function (language, reload) {
            Vue.$cookies.set("smartform-language-" + this.customerCode, language, "1d");
            if (reload) {
                this.$router.go(0);
            }
            let step = {};
            this.lodash.forEach(this.doneSteps, (doneStep) => {
                if (doneStep.id === this.languageForm.id) {
                    let index = this.lodash.findIndex(this.languageForm.options, (option) => {
                        return this.lodash.find(option.value.params, (param) => param.params_type === 'FORM_LANG' && param.lang_code === language);
                    });
                    if (index > -1) {
                        doneStep.optionSelected = this.languageForm.options[index];
                    }
                    step = doneStep;
                    this.$emit('handleHasLocale', true);
                }
            });
            this.currentLanguage = language;
            this.$i18n.locale = language;
            this.$emit('handleSelectedLanguage', language);
            this.doneSteps.splice(1, this.doneSteps.length);
            this.showLanguageModal = false;
            this.$emit("onShowLanguageModalChange", this.showLanguageModal);
            if (!this.codeTag) {
                this.selectNode(step, this.languageForm.id, undefined, language);
            }
        },
        verifyLanguageCookie: function () {
            return Vue.$cookies.get("smartform-language-" + this.customerCode);
        },
        createStep(response) {
            let theStep = {};
            theStep.captcha = response.captcha;
            theStep.show_captcha = response.show_captcha;
            theStep.children = response.children;
            theStep.code_tag = response.code_tag;
            theStep.form_fields = response.form_fields.filter(ff => ![FormFieldTypeCodeEnum.TERMS_CONDITIONS, FormFieldTypeCodeEnum.FOOTER].includes(ff.type));
            theStep.id = response.id;
            theStep.properties = response.properties;
            theStep.tag_description = response.tag_description;
            theStep.options = response.children.map((c) => {
                return { value: c, label: c.tag_description };
            });
            const terms = response.form_fields.find(ff => ff.type === FormFieldTypeCodeEnum.TERMS_CONDITIONS);
            const footer = response.form_fields.find(ff => ff.type === FormFieldTypeCodeEnum.FOOTER);
            if (!this.confirmModalStep) {
                this.confirmModalStep = response.form_fields.find(ff => ff.type === FormFieldTypeCodeEnum.CONFIRM_MODAL);
            }
            if (response.form_fields && response.form_fields.length && (!!footer || !!terms)) {
                this.$store.dispatch('footer/setFooterData', {
                    termsConditions: terms,
                    footerElems: footer
                });
            }
            if (this.searchStr) {
                const regex = new RegExp(`\\b(${this.searchStr})\\b.*?`, 'gmi');
                theStep.form_fields.forEach(field => {
                    if (field.value.text) {
                        field.value.text = field.value.text.replaceAll(regex, (match) => '<mark class="text-mark text-mark-default">' + match + '</mark>');
                    }
                    if (field.value.title) {
                        field.value.title = field.value.title.replaceAll(regex, (match) => '<mark class="text-mark text-mark-default">' + match + '</mark>');
                    }
                    if (field.type === FormFieldTypeCodeEnum.PROPERTY) {
                        theStep.properties.forEach((property) => {
                            if (property.property_code === field.value && property.property_description) {
                                property.property_description = property.property_description.replaceAll(regex, (match) => '<mark class="text-mark text-mark-default">' + match + '</mark>');
                            }
                        });
                    }
                });
            }
            return theStep;
        },
        hasLanguage: function (input) {
            let hasLanguage = false;
            this.languages = [];
            this.languageForm = input;
            this.lodash.forEach(input.children, (child) => {
                if (TagTypeEnum.FORM_LANG === child.tag_type) {
                    hasLanguage = true;
                    this.lodash.forEach(child.params, (params) => {
                        if (params.params_type === "FORM_LANG") {
                            this.languages.push(params.lang_code);
                            //this.languageFlags[params.lang_code] = (this.languageLogoPath + params.lang_code + '.png');
                        }
                    });
                }
            });
            this.$emit("onLanguagesLoaded", this.languages);
            if (hasLanguage) {
                if (this.languages.length !== input.children.length) {
                    console.log("Configurazione form non valida (tag lingua assenti)");
                    swal.fire({
                        text: this.localizationService.CommonLoc('errore_recupero_dati'),
                        icon: 'error',
                        confirmButtonText: this.localizationService.CommonLoc('ricarica_form')
                    }).then(() => {
                        this.reloadForm();
                    });
                }
            }
            return hasLanguage;
        },
        beforeunload() {
            webRestService.formIsClosing(this.customerCode, this.doneSteps[this.doneSteps.length - 1].code_tag, this.isFormSent);
        },
        startForm(code) {
            this.showLanguageModal = false;
            this.$emit("onShowLanguageModalChange", this.showLanguageModal);
            this.sentWithCode = false;
            webRestService
                .getTagsChildren(code, this.customerCode, this.otpCode)
                .then((response) => {
                this.handleStartForm(response);
                if (this.isSurvey) {
                    this.surveyCodeTag = response.code_tag;
                    this.surveyEntryList = response.children;
                    webRestService
                        .getTagsChildren(this.surveyEntryList[0].code_tag, this.customerCode, this.otpCode)
                        .then((response) => {
                        if (response) {
                            this.doneSteps.splice(1, 10);
                            this.manageIncomingQuestion(response);
                        }
                    });
                }
            });
        },
        selectNode(selected, index, step, language, isNavigate) {
            if (step) {
                this.doneSteps[index].optionSelected = step;
            }
            const selectedAnswer = selected.optionSelected.value;
            for (const answer of selected.children) {
                answer.selected = answer.id == selectedAnswer.id;
            }
            if (!isNavigate) {
                this.wizardBreadcrumb.push(selected.optionSelected.value.description);
                this.tagsPath.push(selected.optionSelected.value.code_tag);
            }
            if (selectedAnswer && !this.isSurvey) {
                return this.goToNextQuestion(index, selectedAnswer, language);
            }
        },
        manageIncomingQuestion(response) {
            this.showCaptcha = response.show_captcha;
            response.form_fields = this.lodash.orderBy(response.form_fields, (ff) => ff.ordering);
            let theStep = this.createStep(response);
            theStep.disabledOptions = [];
            this.noResultText = this.localizationService.FormLoc('no_risultato');
            this.lodash.forEach(this.lodash.cloneDeep(theStep.options), (option) => {
                if (option.value.disabled) {
                    let theIndex = this.lodash.findIndex(theStep.options, (opt) => opt.value.id == option.value.id);
                    if (theIndex > -1) {
                        theStep.disabledOptions.push(option);
                        this.noResultText = "";
                        theStep.options.splice(theIndex, 1);
                    }
                }
            });
            this.doneSteps.push(theStep);
            this.updateHeaderFields(response.form_fields, false, this.currentLanguage);
            this.lodash.forEach(theStep.form_fields, (ff) => {
                ff.keyID = this.lodash.uniqueId();
                if (ff.type == "PROPERTY") {
                    ff.value = ff.value + "---" + ff.keyID;
                    this.formFields[ff.value] = undefined;
                    Vue.set(this.formPropertyValidation, ff.value, false);
                    this.formPropertyReadOnly[ff.value] = this.isFieldReadOnly(ff);
                    this.formPropertyMandatory[ff.value] = ff.required;
                }
                if (ff.type == "FURTHER_QUESTIONS") {
                    this.furtherQuestions[ff.id] = undefined;
                }
            });
            if (!this.showHeader) {
                this.scrollToBeginning();
            }
            this.$emit("handleTagChanges", response.code_tag);
        },
        goToPreviousQuestion(index, language) {
            if (!this.surveyCanGoPrevious) {
                return;
            }
            // Get the previous step
            const previousQuestionCodeTag = this.isSurvey
                ? this.surveyEntryList?.[index - 1].code_tag
                : this.doneSteps[index - 1].code_tag;
            this.furtherQuestions = {};
            if (previousQuestionCodeTag) {
                webRestService
                    .getTagsChildren(previousQuestionCodeTag, this.customerCode, this.otpCode)
                    .then((response) => {
                    if (response) {
                        // Remove the latest question from "doneSteps"
                        this.doneSteps.splice(index - 1, 10);
                        this.manageIncomingQuestion(response);
                    }
                });
            }
        },
        goToNextQuestion(index, selectedAnswer, language) {
            if (!this.surveyCanGoNext) {
                return;
            }
            const nextQuestionCodeTag = this.isSurvey
                ? this.surveyEntryList?.[index + 1]?.code_tag
                : selectedAnswer?.code_tag;
            if (nextQuestionCodeTag) {
                globalEventBus.$emit("isLoading", true);
                globalEventBus.$emit("validatePlease");
                setTimeout(() => {
                    this.furtherQuestions = {};
                    webRestService
                        .getTagsChildren(nextQuestionCodeTag, this.customerCode, this.otpCode)
                        .then((response) => {
                        if (response) {
                            this.doneSteps.splice(index + 1, 10);
                            this.manageIncomingQuestion(response);
                        }
                    }).finally(() => globalEventBus.$emit("isLoading", false));
                }, 1000);
            }
        },
        handleStartForm(response) {
            if (response) {
                if (response.show_captcha) {
                    this.showCaptcha = response.show_captcha.valueOf();
                }
                this.noResultText = this.localizationService.FormLoc('no_risultato');
                response.form_fields = this.lodash.orderBy(response.form_fields, (ff) => ff.ordering);
                this.lodash.forEach(response.form_fields, (ff) => {
                    ff.keyID = this.lodash.uniqueId();
                    if (ff.type == "PROPERTY") {
                        ff.value = ff.value + "---" + ff.keyID;
                        this.formPropertyReadOnly[ff.value] = this.isFieldReadOnly(ff);
                        this.formPropertyMandatory[ff.value] = ff.required;
                    }
                });
                let theStep = this.createStep(response);
                theStep.disabledOptions = [];
                this.lodash.forEach(this.lodash.cloneDeep(theStep.options), (option) => {
                    if (option.value.disabled) {
                        let theIndex = this.lodash.findIndex(theStep.options, (opt) => opt.value.id == option.value.id);
                        if (theIndex > -1) {
                            theStep.disabledOptions.push(option);
                            this.noResultText = "";
                            theStep.options.splice(theIndex, 1);
                        }
                    }
                });
                if (this.hasLanguage(theStep)) {
                    this.showLanguageModal = true;
                    this.$emit("onShowLanguageModalChange", this.showLanguageModal);
                    theStep.isLanguage = true;
                    theStep.optionSelected = this.lodash.find(theStep.options, (o) => {
                        let language = this.lodash.find(o.value.params, (params) => params.params_type === "FORM_LANG");
                        return o.value.code_tag == language.lang_code;
                    });
                    let languagesDescription = require('@/i18n/languages_description.json');
                    this.lodash.forEach(this.languages, (language) => {
                        if (Object.keys(languagesDescription).includes(language)) {
                            this.languagesDescription.push(languagesDescription[language]);
                        }
                    });
                    this.$emit("onLanguagesLoaded", this.languages, this.languagesDescription);
                }
                else {
                    this.$i18n.locale = defaultLanguage;
                }
                if (!this.isSurvey) {
                    this.doneSteps.push(theStep);
                }
                if (theStep.isLanguage) {
                    switch (true) {
                        case this.verifyLanguageCookie() != null && this.languages.includes(this.verifyLanguageCookie()):
                            this.setLanguage(this.verifyLanguageCookie(), false);
                            break;
                        case this.languages.includes(this.browserLanguage.split('-')[0]):
                            this.setLanguage(this.browserLanguage.split('-')[0], false);
                            break;
                        case this.languages.length > 0 && this.languages.length == 1:
                            this.setLanguage(this.languages[0], false);
                            break;
                    }
                }
                this.updateHeaderFields(response.form_fields, true);
                if (this.codeTag) {
                    this.navigateForm(this.codeTag, 0);
                }
                else {
                    if (this.searchStr) {
                        const regex = new RegExp(`\\b(${this.searchStr})\\b.*?`, 'gmi');
                        this.doneSteps.forEach(step => {
                            step.options.forEach((opt) => {
                                opt.label = opt.label.replaceAll(regex, (match) => '<mark class="text-mark text-mark-default">' + match + '</mark>');
                            });
                        });
                    }
                    this.wizardBreadcrumb = [];
                }
                this.$emit("handleTagChanges", response.code_tag);
            }
        },
        isDoubleCheckEmailInvalid(sendObject) {
            if (validateEmail(sendObject.email) && validateEmail(sendObject.confirmed_email) && sendObject.email.trim() === sendObject.confirmed_email.trim()) {
                return false;
            }
            return true;
        },
        returnToStep(index) {
            this.wizardBreadcrumb = this.wizardBreadcrumb.slice(0, index + 1);
            this.tagsPath = this.tagsPath.slice(0, index + 1);
            this.doneSteps = this.doneSteps.filter((step, index) => this.tagsPath.includes(step.code_tag) || index === 0);
            this.doneSteps.forEach(step => {
                step.optionSelected = {};
            });
        },
        moveInBreadcrumb(index) {
            if (window.location.href == window.location.href.split(this.customerCode)[0] + this.customerCode + "/open/" + this.tagsPath[index]) {
                window.location.reload();
            }
            else {
                window.location.href = window.location.href.split(this.customerCode)[0] + this.customerCode + "/open/" + this.tagsPath[index];
            }
        },
        getBtnDetails() {
            let btnDetails = {
                "label": localizationService.CommonLoc("Invia Richiesta"),
                "action": "defaultSendBehaviour"
            };
            this.doneSteps.forEach((ds) => ds.form_fields.forEach((ff) => {
                if (ff.type === "SEND_BUTTON_CUSTOMIZATION") {
                    btnDetails = {
                        "label": ff.value.btnLabel,
                        "action": ff.value.btnAction
                    };
                }
            }));
            return btnDetails;
        }
    },
    watch: {
        isSendButtonDisabled() {
            this.$emit('isSendButtonDisabled', this.isSendButtonDisabled);
        }
    }
});
