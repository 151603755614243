import Vue from "vue";
import "isomorphic-fetch";
import Loading from '@/components/Loading.vue';
import TemplateLoader from '@/views/TemplateLoader.vue';
import { globalEventBus } from "@/main";
export default Vue.extend({
    components: {
        Loading,
        TemplateLoader
    },
    data() {
        return { templateName: '' };
    },
    created() {
        const html = document.documentElement; // returns the html tag
        html.setAttribute('lang', 'it');
    },
    mounted() {
        sessionStorage.setItem('firstTime', "true");
        globalEventBus.$on('redirect-url', (url) => {
            this.redirectUrl(url);
        });
    },
    methods: {
        redirectUrl: function (url) {
            globalEventBus.$off('redirect-url');
            this.$router.push(url);
        },
        setTemplateName(templateName) {
            /**
             * Viene settato il nome del template nelle modali per poter applicare lo stile del template correttamente
             */
            document.body.classList.add(templateName);
            document.body.classList.remove(this.templateName);
            this.templateName = templateName;
        }
    }
});
