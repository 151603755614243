import Vue from "vue";
import { FontCaseEnum, ReadOnlyType } from "@/enums";
import FieldInfo from "@/components/FieldTypes/FieldInfo.vue";
import { globalEventBus, localizationService } from '@/main';
import Mixin from '../../mixins/functionMixin';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
export default Vue.extend({
    name: 'PropertyType',
    mixins: [Mixin],
    components: {
        FieldInfo,
        VueSlider
    },
    data() {
        return {
            defaultLoaded: false,
            restSelectDisabled: true,
            elencoData: Object,
            touched: true,
            propValue: "",
            propValueDateFormatted: "",
            propValueArrayText: [],
            propValueArrayNumber: [],
            propValueFile: [],
            propValueFileSelect: [],
            propValueSelect: undefined,
            propValueSelectDataSource: undefined,
            propValueMultiCheck: {},
            timePickerValue: "00:00:00",
            thisState: undefined,
            validationErrors: "",
            validationErrorsArrayText: [],
            validationErrorsArrayNumber: [],
            validationStatesArrayText: [],
            validationStatesArrayNumber: [],
            size_exceeded: false,
            valid_file_type: true,
            localizationService,
            ReadOnlyType,
            currentDate: 0,
            selectedSliderValue: "",
            sliderColor: "",
            sliderTooltipVisible: "active",
            sliderTooltipPosition: "right",
            submited: false
        };
    },
    props: {
        isSurvey: Boolean,
        formFields: {},
        parentIndex: Number,
        field: Object,
        userInfos: { type: Object },
        property: Object
    },
    methods: {
        verifyMultiCheckbox(multiCheckboxProperty) {
            let selectedCheckbox = 0;
            this.lodash.forEach(multiCheckboxProperty, (cb) => {
                if (cb === true || cb === "true") {
                    selectedCheckbox++;
                }
            });
            if (!this.submited) {
                if (selectedCheckbox == 0 && this.thisState === undefined) {
                    return;
                }
            }
            if (selectedCheckbox == 0 && this.getFormFieldRequired()) {
                return false;
            }
            if (selectedCheckbox > +this.propertyComputed?.default_value?.max_selected) {
                return false;
            }
            return true;
        },
        removeAttachment(index) {
            this.propValueFile.splice(index, 1);
            if (this.propValueFile.length == 0) {
                this.$refs['attachments'].reset();
            }
            this.propertyIn();
        },
        getFileIcon(fileType) {
            let fileTypeIcon = "file";
            switch (fileType) {
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                case "application/vnd.ms-excel":
                    fileTypeIcon = "xls";
                    break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                case "application/msword":
                    fileTypeIcon = "doc";
                    break;
                case "image/jpeg":
                case "image/jpg":
                    fileTypeIcon = "jpg";
                    break;
                case "image/png":
                    fileTypeIcon = "png";
                    break;
                case "application/pdf":
                    fileTypeIcon = "pdf";
                    break;
            }
            return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="FileIcon FileIcon--' + fileTypeIcon + '" viewBox="-4 0 42 48" width="42" height="48">\n' +
                '  <polygon class="FileIcon-base" points="38 48 0 48 0 0 26 0 38 12 38 48" />\n' +
                '  <polygon class="FileIcon-front" points="35 45 3 45 3 3 22 3 22 16 35 16 35 45" fill="#fff" />\n' +
                '  <polygon class="FileIcon-fold" points="25 3 35 13 25 13 25 3" fill="#fff" />\n' +
                '  <rect class="FileIcon-badge" x="-4" y="21" width="34" height="18" rx="3" />\n' +
                '  <text class="FileIcon-label" text-anchor="middle" x="13" y="34" font-size="12" fill="#fff">' + fileTypeIcon + '</text>\n' +
                '</svg>';
        },
        checkAttachmentsValidType(acceptable, fileType) {
            let isValid = false;
            let acceptableTypes = acceptable.replace("*", "").split(",");
            acceptableTypes.forEach(function (type) {
                isValid = isValid || fileType.trim().includes(type.trim());
            });
            return isValid;
        },
        selectPropValue(n) {
            this.propValueSelect = n;
            this.propertyIn();
        },
        selectDataSourcePropValue(n) {
            this.propValueSelectDataSource = n;
            // this.propertyIn();
        },
        prettyBytes(num) {
            if (!isNaN(num)) {
                var exponent;
                var unit;
                var neg = num < 0;
                var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                if (neg) {
                    num = -num;
                }
                if (num < 1) {
                    return (neg ? '-' : '') + num + ' B';
                }
                exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1);
                num = parseFloat((num / Math.pow(1024, exponent)).toFixed(2));
                unit = units[exponent];
                return (neg ? '-' : '') + num + ' ' + unit;
            }
            return "n/a";
        },
        getFormFieldReadOnly() {
            return (this.field?.readonly && this.field.readonly === this.ReadOnlyType.LOCKED)
                || (this.propertyComputed?.default_value?.elementsType?.value === "restCall" && this.restSelectDisabled)
                || (this.field.readonly === this.ReadOnlyType.UNLOCKED
                    && this.field.mapping?.trim() != "" && this.userInfos
                    && this.userInfos[this.field.mapping] != undefined
                    && this.userInfos[this.field.mapping]?.toString().trim() !== "");
        },
        getFormFieldHasInfo() {
            return !!this.field.info_title && this.field.info_title?.trim() != "" && !!this.field.info_text && this.field.info_text?.trim() != "";
        },
        getFormFieldRequired() {
            return this.field && this.field.required;
        },
        getMinDateFormField() {
            if (this.propertyComputed?.default_value?.relative_min_period
                && this.propertyComputed?.default_value?.relative_min_value) {
                const dayInMillis = 24 * 60 * 60 * 1000;
                let value = Math.abs(this.propertyComputed.default_value.relative_min_value);
                switch (this.propertyComputed.default_value.relative_min_period) {
                    case 'days':
                        value = value * dayInMillis;
                        break;
                    case 'weeks':
                        value = value * dayInMillis * 7;
                        break;
                    case 'months':
                        value = value * dayInMillis * 30;
                        break;
                    case 'years':
                        value = value * dayInMillis * 365;
                        break;
                    default:
                        break;
                }
                return new Date(this.currentDate - value);
            }
            else if (this.propertyComputed?.default_value?.min) {
                return new Date(this.propertyComputed?.default_value?.min);
            }
            return null;
        },
        getMaxDateFormField() {
            if (this.propertyComputed?.default_value?.relative_max_period
                && this.propertyComputed?.default_value?.relative_max_value) {
                const dayInMillis = 24 * 60 * 60 * 1000;
                let value = this.propertyComputed.default_value.relative_max_value;
                switch (this.propertyComputed.default_value.relative_max_period) {
                    case 'days':
                        value = value * dayInMillis;
                        break;
                    case 'weeks':
                        value = value * dayInMillis * 7;
                        break;
                    case 'months':
                        value = value * dayInMillis * 30;
                        break;
                    case 'years':
                        value = value * dayInMillis * 365;
                        break;
                    default:
                        break;
                }
                return new Date(this.currentDate + value);
            }
            else if (this.propertyComputed?.default_value?.max) {
                return new Date(this.propertyComputed?.default_value?.max);
            }
            return null;
        },
        hasTimepicker() {
            return !!this.propertyComputed?.default_value?.time;
        },
        getSelectOptions() {
            let options = [];
            if (this.propertyComputed?.default_value?.options) {
                this.propertyComputed.default_value.options.forEach((opt) => {
                    options.push({ value: opt.value, label: opt.key, icon: opt && opt.icon ? opt.icon : {} });
                });
            }
            return options;
        },
        getSelectDataSourcePayloads() {
            globalEventBus.$on('restSelectDataSourcePayloads', (dati) => {
                this.fillRestPayloads(dati);
            });
            let options = [];
            if (this.propertyComputed?.default_value?.options) {
                let optionKeys = Object.keys(this.propertyComputed.default_value.options);
                optionKeys.forEach((key) => {
                    options.push({ value: this.propertyComputed.default_value.options[key], label: key });
                });
            }
            return options;
        },
        getSSOSelectOptions() {
            if (this.property?.default_value?.label) {
                let label = this.property.default_value.label;
                let regex = RegExp("{{(.*?)}}", "gm");
                let arrLabel = [];
                let match;
                while ((match = regex.exec(label)) != null) {
                    arrLabel.push(match[1]);
                }
                if (arrLabel.length) {
                    return this.userInfos[this.field.mapping]?.map((c) => {
                        let obj = {
                            value: JSON.stringify(c),
                            label: ""
                        };
                        this.lodash.forEach(arrLabel, (element) => {
                            if (c[element]) {
                                obj.label += c[element] + " ";
                            }
                        });
                        return obj;
                    });
                }
            }
            else if (this.userInfos && Array.isArray(this.userInfos[this.field.mapping])) {
                return this.userInfos[this.field.mapping].map((c) => {
                    return { value: c.value, label: c.key };
                });
            }
            return false;
        },
        isButtons() {
            return !!(this.propertyComputed && this.propertyComputed.default_value && this.propertyComputed.default_value &&
                this.propertyComputed.default_value.isButtons && this.propertyComputed.default_value.isButtons);
        },
        getFormFieldType() {
            let theType = "";
            if (this.propertyComputed && this.propertyComputed.default_value && this.propertyComputed.default_value.input_type) {
                theType = this.propertyComputed.default_value.input_type == 'codfis' ? 'varchar' : this.propertyComputed.default_value.input_type;
            }
            if (this.field && this.field.hidden) {
                theType = "hidden";
            }
            return theType;
        },
        fillRestPayloads(dati) {
            if (dati.selectID === this.property?.property_code) {
                // this.propValueSelectDataSource = null;
                this.propertyComputed.default_value.options = dati.options;
            }
        },
        updateThisState() {
            let isValid = undefined;
            isValid = true;
            this.validationErrors = "";
            // if (this.getFormFieldType() == "boolean") {
            //   this.$emit("propertyIn", {
            //     code: this.field.value,
            //     value: this.propValue.trim() != "" ? this.propValue : "false",
            //     type: this.field.type,
            //     input_type: this.getFormFieldType(),
            //     size_exceeded: this.size_exceeded,
            //     valid_file_type: this.valid_file_type
            //   });
            // }
            if (this.touched) {
                if (this.propertyComputed && this.propertyComputed.default_value && this.propertyComputed.default_value.input_type && this.propertyComputed.default_value.input_type == 'array_number') {
                    for (let i = 0; i < this.propValueArrayNumber.length; i++) {
                        isValid = true;
                        this.validationErrorsArrayNumber[i] = -1;
                        Vue.set(this.validationErrorsArrayNumber, i, "");
                        Vue.set(this.validationStatesArrayNumber, i, true);
                        if (isValid && this.getFormFieldRequired()) {
                            isValid = isValid && this.propValueArrayNumber[i] != null || this.propValueArrayNumber[i] != undefined; //check per required
                            if (!isValid) {
                                Vue.set(this.validationErrorsArrayNumber, i, this.localizationService.PropertyLoc('campo_richiesto'));
                                Vue.set(this.validationStatesArrayNumber, i, false);
                            }
                        }
                        if (this.propertyComputed.default_value.max && +this.propValueArrayNumber[i] > this.propertyComputed.default_value.max) {
                            isValid = false;
                        }
                        if (this.propertyComputed.default_value.min && +this.propValueArrayNumber[i] < this.propertyComputed.default_value.min) {
                            isValid = false;
                        }
                        if (!isValid) {
                            Vue.set(this.validationErrorsArrayNumber, i, this.localizationService.PropertyLoc('campo_formato'));
                            Vue.set(this.validationStatesArrayNumber, i, false);
                        }
                    }
                }
                else if (this.propertyComputed && this.propertyComputed.default_value && this.propertyComputed.default_value.input_type && this.propertyComputed.default_value.input_type == 'array_text') {
                    for (let i = 0; i < this.propValueArrayText.length; i++) {
                        isValid = undefined;
                        this.validationErrorsArrayText[i] = "";
                        Vue.set(this.validationErrorsArrayText, i, "");
                        Vue.set(this.validationStatesArrayText, i, true);
                        if (this.getFormFieldRequired()) {
                            isValid = this.propValueArrayText[i].trim().length > 0; //check per required
                            if (!isValid) {
                                Vue.set(this.validationErrorsArrayText, i, this.localizationService.PropertyLoc('campo_richiesto'));
                                Vue.set(this.validationStatesArrayText, i, false);
                            }
                        }
                        if (this.propertyComputed.default_value.max_len && +this.propValueArrayText[i].trim().length > this.propertyComputed.default_value.max_len) {
                            isValid = false;
                        }
                        if (+this.propValueArrayText[i].trim().length > 0 && this.propertyComputed.default_value.min_len && +this.propValueArrayText[i].trim().length < this.propertyComputed.default_value.min_len) {
                            isValid = false;
                        }
                        if (+this.propValueArrayText[i].trim().length > 0 && this.propertyComputed.default_value.validation_mask && !this.checkValidationMask(this.propValueArrayText[i], this.propertyComputed.default_value.validation_mask)) {
                            isValid = false;
                        }
                        if (isValid === false) {
                            Vue.set(this.validationErrorsArrayText, i, this.localizationService.PropertyLoc('campo_formato'));
                            Vue.set(this.validationStatesArrayText, i, false);
                        }
                    }
                }
                else {
                    if (this.propertyComputed.default_value && this.propertyComputed.default_value) {
                        let isNotEmpty = false;
                        let isValidDate = true;
                        switch (this.propertyComputed.default_value.input_type) {
                            case 'file':
                                isNotEmpty = this.propValueFileSelect && Array.isArray(this.propValueFileSelect) && this.propValueFileSelect.length > 0 || this.propValueFileSelect && !Array.isArray(this.propValueFileSelect) && this.propValueFileSelect.size != null;
                                break;
                            case 'selectssooptions':
                                break;
                            case 'int':
                                isValid = true;
                                if (this.propValue.length) {
                                    isNotEmpty = true;
                                    if (isNaN(parseInt(this.propValue))) {
                                        isValid = false;
                                        break;
                                    }
                                    let value = parseInt(this.propValue);
                                    if (this.propertyComputed.default_value.min && value < this.propertyComputed.default_value.min) {
                                        isValid = false;
                                        break;
                                    }
                                    if (this.propertyComputed.default_value.max && value > this.propertyComputed.default_value.max) {
                                        isValid = false;
                                        break;
                                    }
                                }
                                break;
                            case 'numeric':
                                isValid = true;
                                if (this.propValue.length) {
                                    isNotEmpty = true;
                                    if (isNaN(parseFloat(this.propValue))) {
                                        isValid = false;
                                        break;
                                    }
                                    let value = parseFloat(this.propValue);
                                    if (this.propertyComputed.default_value.min && value < this.propertyComputed.default_value.min) {
                                        isValid = false;
                                        break;
                                    }
                                    if (this.propertyComputed.default_value.max && value > this.propertyComputed.default_value.max) {
                                        isValid = false;
                                        break;
                                    }
                                }
                                break;
                            case 'select':
                            case 'select_data_source':
                                isNotEmpty = this.propValueSelect != undefined;
                                break;
                            case 'boolean':
                                if (this.propValue !== undefined && this.propValue !== "") {
                                    isNotEmpty = this.getFormFieldRequired() ? JSON.parse(this.propValue) : true;
                                }
                                break;
                            case 'date':
                                if (this.propertyComputed?.default_value?.relative_min_period
                                    && this.propertyComputed?.default_value?.relative_min_value) {
                                    const dayInMillis = 24 * 60 * 60 * 1000;
                                    let value = Math.abs(this.propertyComputed.default_value.relative_min_value);
                                    switch (this.propertyComputed.default_value.relative_min_period) {
                                        case 'days':
                                            value = value * dayInMillis;
                                            break;
                                        case 'weeks':
                                            value = value * dayInMillis * 7;
                                            break;
                                        case 'months':
                                            value = value * dayInMillis * 30;
                                            break;
                                        case 'years':
                                            value = value * dayInMillis * 365;
                                            break;
                                        default:
                                            break;
                                    }
                                    let validationDate = new Date(this.currentDate - value - dayInMillis);
                                    if (this.propValue < validationDate) {
                                        isValidDate = false;
                                    }
                                }
                                else if (this.propertyComputed?.default_value?.min) {
                                    // data minima
                                    if (this.propValue < new Date(this.propertyComputed.default_value.min)) {
                                        isValidDate = false;
                                    }
                                }
                                if (this.propertyComputed?.default_value?.relative_max_period
                                    && this.propertyComputed?.default_value?.relative_max_value) {
                                    const dayInMillis = 24 * 60 * 60 * 1000;
                                    let value = this.propertyComputed.default_value.relative_max_value;
                                    switch (this.propertyComputed.default_value.relative_max_period) {
                                        case 'days':
                                            value = value * dayInMillis;
                                            break;
                                        case 'weeks':
                                            value = value * dayInMillis * 7;
                                            break;
                                        case 'months':
                                            value = value * dayInMillis * 30;
                                            break;
                                        case 'years':
                                            value = value * dayInMillis * 365;
                                            break;
                                        default:
                                            break;
                                    }
                                    if (value < 0 && new Date(this.currentDate + value) < this.propValue) {
                                        isValidDate = false;
                                    }
                                    else if (value > 0 && new Date(this.currentDate + value) < this.propValue) {
                                        isValidDate = false;
                                    }
                                }
                                else if (this.propertyComputed?.default_value?.max) {
                                    // data massima
                                    if (this.propValue > new Date(this.propertyComputed.default_value.max)) {
                                        isValidDate = false;
                                    }
                                }
                                isNotEmpty = !!this.propValue && (!this.hasTimepicker() || (this.hasTimepicker() && !!this.timePickerValue));
                                break;
                            case 'multi_checkbox':
                                isValid = this.verifyMultiCheckbox(this.propValueMultiCheck);
                                isNotEmpty = isValid === true;
                                break;
                            case 'slider':
                                isValid = !this.getFormFieldRequired() || (this.getFormFieldRequired() && this.propValue.trim() != "");
                                isNotEmpty = isValid;
                                break;
                            case 'codfis':
                            case 'varchar':
                            case 'otp':
                            case 'claimdirect':
                            case 'claimindirect':
                            case 'genericclaimdirect':
                            case 'genericclaimindirect':
                            case 'email_ticket':
                                isNotEmpty = this.propValue.toString().trim().length > 0;
                                break;
                            case 'json':
                            case 'textarea':
                                isNotEmpty = this.propValue.toString().trim().length > 0;
                                break;
                            case 'email':
                                isValid = true;
                                let emailRegexp = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$';
                                if (this.propValue) {
                                    isValid = this.checkValidationMask(this.propValue, emailRegexp);
                                    isNotEmpty = this.propValue.trim().length > 0;
                                }
                                break;
                            default:
                                isNotEmpty = this.propValue.toString().trim().length > 0; //check per required
                                break;
                        }
                        if ((this.getFormFieldRequired() || isNotEmpty)) {
                            isValid = isValid && (!this.getFormFieldRequired() || (this.getFormFieldRequired() && isNotEmpty));
                            if (!isValid) {
                                this.validationErrors = this.localizationService.PropertyLoc('campo_richiesto').toString();
                                this.thisState = isValid;
                                return;
                            }
                            if (!isValidDate) {
                                this.validationErrors = this.localizationService.PropertyLoc('La data non rispecchia i limiti impostati').toString();
                                this.thisState = isValidDate;
                                return;
                            }
                            if (this.propertyComputed.default_value.validation_mask && this.propertyComputed.default_value.validation_mask.trim() != "") {
                                isValid = isValid && new RegExp(this.propertyComputed.default_value.validation_mask).test(this.propValue.trim());
                                if (!isValid) {
                                    this.validationErrors = this.localizationService.PropertyLoc('campo_formato').toString();
                                    this.thisState = isValid;
                                    return;
                                }
                            }
                            if (this.propertyComputed.default_value.validation && this.propertyComputed.default_value.validation.trim() != "") {
                                isValid = isValid && new RegExp(this.propertyComputed.default_value.validation.toLowerCase()).test(this.propValue.toLowerCase().trim());
                                if (!isValid) {
                                    this.validationErrors = this.localizationService.PropertyLoc('campo_formato').toString();
                                    this.thisState = isValid;
                                    return;
                                }
                            }
                            if (this.propertyComputed.default_value.min && this.propertyComputed.default_value.min > 0) {
                                isValid =
                                    isValid &&
                                        this.propValue.length >=
                                            this.propertyComputed.default_value.min; //check per lunghezza minima
                                if (!isValid) {
                                    this.validationErrors = this.localizationService.PropertyLoc('campo_lungo') + " " + this.propertyComputed.default_value.min + " " + this.localizationService.PropertyLoc('caratteri') + "<br />";
                                    this.thisState = isValid;
                                    return;
                                }
                            }
                            if (this.propertyComputed.default_value.min_len && this.propertyComputed.default_value.min_len > 0) {
                                isValid =
                                    isValid &&
                                        this.propValue.length >=
                                            this.propertyComputed.default_value.min_len; //check per lunghezza minima
                                if (!isValid) {
                                    this.validationErrors = this.localizationService.PropertyLoc('campo_lungo') + " " + this.propertyComputed.default_value.min_len + " " + this.localizationService.PropertyLoc('caratteri') + "<br />";
                                    this.thisState = isValid;
                                    return;
                                }
                            }
                            if (this.propertyComputed.default_value.max && this.propertyComputed.default_value.max > 0) {
                                isValid =
                                    isValid &&
                                        this.propValue.length <=
                                            this.propertyComputed.default_value.max; //check per lunghezza massima
                                if (!isValid) {
                                    this.validationErrors = this.localizationService.PropertyLoc('campo_corto') + " " + this.propertyComputed.default_value.max + " " + this.localizationService.PropertyLoc('caratteri') + "<br />";
                                    this.thisState = isValid;
                                    return;
                                }
                            }
                            if (this.propertyComputed.default_value.max_len && this.propertyComputed.default_value.max_len > 0) {
                                isValid =
                                    isValid &&
                                        this.propValue.length <=
                                            this.propertyComputed.default_value.max_len; //check per lunghezza massima
                                if (!isValid) {
                                    this.validationErrors = this.localizationService.PropertyLoc('campo_corto') + " " + this.propertyComputed.default_value.max_len + " " + this.localizationService.PropertyLoc('caratteri') + "<br />";
                                    this.thisState = isValid;
                                    return;
                                }
                            }
                            if (isValid && this.propertyComputed.default_value.max_size && this.propertyComputed.default_value.max_size > 0) {
                                this.size_exceeded = false;
                                this.valid_file_type = true;
                                let totSize = 0;
                                let erroreMsg = "";
                                if (Array.isArray(this.propValueFileSelect[this.propValueFileSelect.length - 1])) {
                                    var that = this;
                                    this.lodash.forEach(this.propValueFileSelect[this.propValueFileSelect.length - 1], (v, k) => {
                                        totSize = totSize + v.size;
                                        if (totSize >= this.propertyComputed.default_value.max_size * 1024) {
                                            erroreMsg = that.localizationService.PropertyLoc('dimensione_file_max') + " " + that.prettyBytes(this.propertyComputed.default_value.max_size * 1024);
                                            that.size_exceeded = true;
                                        }
                                        that.valid_file_type = that.checkAttachmentsValidType(this.propertyComputed?.default_value?.file_type, v.type);
                                        if (!that.valid_file_type) {
                                            erroreMsg = that.localizationService.PropertyLoc('Tipo file non valido');
                                        }
                                    });
                                }
                                else {
                                    if (this.propValueFileSelect[this.propValueFileSelect.length - 1]) {
                                        totSize = this.propValueFileSelect[this.propValueFileSelect.length - 1].size;
                                        if (totSize >= this.propertyComputed.default_value.max_size * 1024) {
                                            if (!!this.propertyComputed.default_value.multiple) {
                                                erroreMsg = this.localizationService.PropertyLoc('dimensione_file_max') + " " + this.prettyBytes(this.propertyComputed.default_value.max_size * 1024);
                                            }
                                            else {
                                                erroreMsg = this.localizationService.PropertyLoc('somma_dimensione_file_max') + " " + this.prettyBytes(this.propertyComputed.default_value.max_size * 1024);
                                            }
                                            this.size_exceeded = true;
                                        }
                                        this.valid_file_type = this.checkAttachmentsValidType(this.propertyComputed.default_value.file_type, this.propValueFileSelect[this.propValueFileSelect.length - 1].type);
                                        if (!this.valid_file_type) {
                                            erroreMsg = this.localizationService.PropertyLoc('Tipo file non valido');
                                        }
                                    }
                                }
                                isValid =
                                    isValid &&
                                        !this.size_exceeded && //check per dimensione massima
                                        this.valid_file_type; //check per tipo file
                                if (!isValid) {
                                    this.size_exceeded = true;
                                    this.validationErrors = erroreMsg;
                                    this.thisState = isValid;
                                }
                            }
                            if (this.propertyComputed.default_value.input_type == 'codfis') {
                                this.propValue = this.propValue.toUpperCase();
                                if (this.propValue.trim().length == 16) {
                                    var ilCodiceFiscaleOriginale = {
                                        cognome: this.propValue.trim().slice(0, 3),
                                        nome: this.propValue.trim().slice(3, 6),
                                        anno: parseInt(this.propValue.trim().slice(6, 8)),
                                        mese: this.propValue.trim().slice(8, 9),
                                        giorno: parseInt(this.propValue.trim().slice(9, 11)),
                                        luogo: this.propValue.trim().slice(11, 15),
                                        check: this.propValue.trim().slice(15, 16),
                                        sesso: ""
                                    };
                                    //sarebbe bello usare il copy
                                    var ilCodiceFiscale = {
                                        cognome: this.propValue.trim().slice(0, 3),
                                        nome: this.propValue.trim().slice(3, 6),
                                        anno: parseInt(this.propValue.trim().slice(6, 8)),
                                        mese: this.propValue.trim().slice(8, 9),
                                        giorno: parseInt(this.propValue.trim().slice(9, 11)),
                                        luogo: this.propValue.trim().slice(11, 15),
                                        check: this.propValue.trim().slice(15, 16),
                                        sesso: ""
                                    };
                                    if (ilCodiceFiscale.giorno > 31) {
                                        ilCodiceFiscale.sesso = 'F';
                                        ilCodiceFiscale.giorno -= 40;
                                    }
                                    else {
                                        ilCodiceFiscale.sesso = 'M';
                                    }
                                    this.CodiceFiscale();
                                    this.setNome(ilCodiceFiscale.nome);
                                    this.setCognome(ilCodiceFiscale.cognome);
                                    this.setSesso(ilCodiceFiscale.sesso);
                                    this.setDataNascita(this.generaDataNascita("" + ("0" + ilCodiceFiscale.anno).slice(-2) + ilCodiceFiscale.mese + ilCodiceFiscale.giorno, ilCodiceFiscale.sesso));
                                    this.setComune(ilCodiceFiscale.luogo);
                                    let cfGenerato = this.genera();
                                    let cfInserito = this.propValue.trim();
                                    if (cfGenerato != cfInserito) {
                                        // cfInserito = (this as any).checkValidOmocodia(cfInserito);
                                        ilCodiceFiscale.luogo = cfInserito.slice(11, 15).toUpperCase();
                                        ilCodiceFiscale.anno = parseInt(cfInserito.slice(6, 8));
                                        ilCodiceFiscale.giorno = parseInt(cfInserito.slice(9, 11));
                                        if (ilCodiceFiscale.giorno > 31) {
                                            ilCodiceFiscale.giorno -= 40;
                                        }
                                        ilCodiceFiscale.nome = this.cleanXs(ilCodiceFiscale.nome);
                                        ilCodiceFiscale.cognome = this.cleanXs(ilCodiceFiscale.cognome);
                                        this.setNome(ilCodiceFiscale.nome);
                                        this.setCognome(ilCodiceFiscale.cognome);
                                        this.setDataNascita(this.generaDataNascita("" + ilCodiceFiscale.anno + ilCodiceFiscale.mese + ilCodiceFiscale.giorno, ilCodiceFiscale.sesso));
                                        this.setComune(ilCodiceFiscale.luogo);
                                        isValid = isValid && (cfInserito == this.genera());
                                        if (!isValid) {
                                            this.validationErrors = this.localizationService.PropertyLoc("Il codice fiscale inserito non è corretto");
                                            this.thisState = false;
                                            return;
                                        }
                                    }
                                    let iCampi = [];
                                    this.lodash.forEach(this.formFields, (v, k) => iCampi[k.split("---")[0]] = v);
                                    if (this.propertyComputed?.default_value?.name) {
                                        if (this.propertyComputed.default_value.name.trim() != "" && iCampi[this.propertyComputed.default_value.name.trim()]) {
                                            let nameInserted = iCampi[this.propertyComputed?.default_value?.name.trim()];
                                            let nomeValido = ilCodiceFiscale.nome == this.controlla_nome(nameInserted);
                                            isValid = isValid && nomeValido;
                                            if (!nomeValido) {
                                                this.validationErrors = this.localizationService.PropertyLoc("Il nome inserito nell'apposito campo non corrisponde a quello indicato nel codice fiscale");
                                                this.thisState = false;
                                                return;
                                            }
                                        }
                                        else {
                                            this.validationErrors = this.localizationService.PropertyLoc("Il nome inserito nell'apposito campo non corrisponde a quello indicato nel codice fiscale");
                                            isValid = false;
                                            this.thisState = false;
                                            return;
                                        }
                                    }
                                    if (this.propertyComputed?.default_value?.surname) {
                                        if (this.propertyComputed?.default_value?.surname.trim() != "" && iCampi[this.propertyComputed?.default_value?.surname.trim()]) {
                                            let surnameInserted = iCampi[this.propertyComputed?.default_value?.surname.trim()];
                                            isValid = isValid && ilCodiceFiscale.cognome == this.controlla_cognome(surnameInserted);
                                            if (!isValid) {
                                                this.validationErrors = this.localizationService.PropertyLoc("Il cognome inserito nell'apposito campo non corrisponde a quello indicato nel codice fiscale");
                                                this.thisState = false;
                                                return;
                                            }
                                        }
                                        else {
                                            this.validationErrors = this.localizationService.PropertyLoc("Il cognome inserito nell'apposito campo non corrisponde a quello indicato nel codice fiscale");
                                            isValid = false;
                                            this.thisState = false;
                                            return;
                                        }
                                    }
                                    if (this.propertyComputed?.default_value?.birthday && this.propertyComputed?.default_value?.gender) {
                                        if (this.propertyComputed?.default_value?.birthday.trim() != "" &&
                                            this.propertyComputed?.default_value?.gender.trim() != "" &&
                                            iCampi[this.propertyComputed?.default_value?.birthday.trim()] &&
                                            iCampi[this.propertyComputed?.default_value?.gender.trim()]) {
                                            let birthdayInserted = iCampi[this.propertyComputed?.default_value?.birthday.trim()];
                                            let sexInserted = iCampi[this.propertyComputed?.default_value?.gender.trim()].toLowerCase();
                                            let toCheck = "" + ("0" + ilCodiceFiscaleOriginale.anno).slice(-2) + ilCodiceFiscaleOriginale.mese + ("0" + ilCodiceFiscaleOriginale.giorno).slice(-2);
                                            isValid = isValid && (toCheck == this.controlla_nascita(birthdayInserted, sexInserted));
                                            if (!isValid) {
                                                this.validationErrors = this.localizationService.PropertyLoc("La data di nascita o il sesso inseriti negli appositi campi non corrispondono a quelli indicati nel codice fiscale inserito");
                                                this.thisState = false;
                                                return;
                                            }
                                        }
                                        else {
                                            this.validationErrors = this.localizationService.PropertyLoc("La data di nascita o il sesso inseriti negli appositi campi non corrispondono a quelli indicati nel codice fiscale inserito");
                                            isValid = false;
                                            this.thisState = false;
                                            return;
                                        }
                                    }
                                    if (this.propertyComputed?.default_value?.birthplace) {
                                        if (this.propertyComputed?.default_value?.birthplace.trim() != "" &&
                                            iCampi[this.propertyComputed?.default_value?.birthplace.trim()]) {
                                            let birthplaceInserted = iCampi[this.propertyComputed?.default_value?.birthplace.trim()];
                                            isValid = isValid && (birthplaceInserted == ilCodiceFiscale.luogo);
                                            if (!isValid) {
                                                this.validationErrors = this.localizationService.PropertyLoc("Il comune di nascita inserito nell'apposito campo non corrisponde a quello indicato nel codice fiscale");
                                                this.thisState = false;
                                                return;
                                            }
                                        }
                                        else {
                                            this.validationErrors = this.localizationService.PropertyLoc("Il comune di nascita inserito nell'apposito campo non corrisponde a quello indicato nel codice fiscale");
                                            isValid = false;
                                            this.thisState = false;
                                            return;
                                        }
                                    }
                                }
                                else {
                                    isValid = false;
                                    this.validationErrors = this.localizationService.PropertyLoc("Il codice fiscale deve deve avere 16 caratteri");
                                    this.thisState = false;
                                    return;
                                }
                            }
                        }
                    }
                }
                this.thisState = isValid;
            }
            else {
                this.thisState = undefined;
            }
        },
        checkValidationMask(valore, regexp) {
            return new RegExp(regexp).test(valore);
        },
        addArrayTextElement() {
            if (!this.propertyComputed.default_value.nelementsmax || this.validationStatesArrayText[this.validationStatesArrayText.length - 1] ||
                ((this.propertyComputed.default_value &&
                    this.propertyComputed.default_value &&
                    this.propertyComputed.default_value.nelementsmin &&
                    this.propertyComputed.default_value.nelementsmin.value == 0) &&
                    this.validationStatesArrayText.length == 0)) {
                this.propValueArrayText.push("");
                this.validationErrorsArrayText.push("");
                this.validationStatesArrayText.push(null);
            }
            this.propertyIn();
        },
        addArrayNumberElement() {
            if (!this.propertyComputed.default_value.nelementsmax || this.validationStatesArrayNumber[this.validationStatesArrayNumber.length - 1] ||
                ((this.propertyComputed.default_value &&
                    this.propertyComputed.default_value &&
                    this.propertyComputed.default_value.nelementsmin &&
                    this.propertyComputed.default_value.nelementsmin.value == 0) &&
                    this.validationStatesArrayNumber.length == 0)) {
                this.propValueArrayNumber.push(this.propertyComputed.default_value.min ?? 0);
                this.validationErrorsArrayNumber.push(-1);
                this.validationStatesArrayNumber.push(null);
            }
            this.propertyIn();
        },
        deleteArrayNumberElement(index) {
            this.propValueArrayNumber.splice(index, 1);
            this.validationErrorsArrayNumber.splice(index, 1);
            this.validationStatesArrayNumber.splice(index, 1);
            this.propertyIn();
        },
        deleteArrayTextElement(index) {
            this.propValueArrayText.splice(index, 1);
            this.validationErrorsArrayText.splice(index, 1);
            this.validationStatesArrayText.splice(index, 1);
            this.propertyIn();
        },
        propertyDateIn() {
            let separatore = this.propertyComputed?.default_value?.separator;
            let dataFormat = this.propValueDateFormatted.split(separatore);
            let correctFormat = this.propertyDateFormat.split(separatore);
            if (this.propValueDateFormatted.length == this.propertyDateFormat.length) {
                let toDate = this.moment(this.propValueDateFormatted, this.propertyDateFormat).toDate();
                if (Object.prototype.toString.call(toDate) === "[object Date]" && !isNaN(toDate)) {
                    if (dataFormat.length == 3) {
                        if (correctFormat[0].length == dataFormat[0].length && correctFormat[1].length == dataFormat[1].length && correctFormat[2].length == dataFormat[2].length) {
                            this.propValue = toDate;
                            this.thisState = true;
                            this.propertyIn();
                        }
                        else {
                            this.thisState = false;
                        }
                    }
                    else {
                        this.thisState = false;
                    }
                }
                else {
                    this.propValueDateFormatted = this.localizationService.PropertyLoc("Non valida");
                    this.thisState = false;
                }
            }
            else {
                let ultimo = dataFormat[dataFormat.length - 1];
                if (ultimo.length == correctFormat[dataFormat.length - 1].length) {
                    this.propValueDateFormatted += separatore;
                }
                this.thisState = false;
            }
            if (!this.thisState) {
                this.propValue = undefined;
                this.validationErrors = this.localizationService.PropertyLoc("Inserire la data con il seguente formato: " + this.propertyDateFormat);
            }
        },
        propertyIn() {
            this.updateThisState();
            let theValue;
            let theInputType;
            if (this.propertyComputed && this.propertyComputed.default_value) {
                theInputType = this.propertyComputed.default_value.input_type;
                switch (true) {
                    case ['date'].includes(this.getFormFieldType()):
                        theValue = new Date(this.propValue);
                        if (this.hasTimepicker()) {
                            if (this.timePickerValue.trim() != "") {
                                if (isNaN(theValue.getTime())) {
                                    let dataOggi = new Date();
                                    theValue = dataOggi;
                                    this.propValue = dataOggi;
                                }
                            }
                            theValue.setHours(parseInt(this.timePickerValue?.split(":")[0]));
                            theValue.setMinutes(parseInt(this.timePickerValue?.split(":")[1]));
                        }
                        else {
                            theValue.setHours(12);
                            theValue.setMinutes(0);
                        }
                        this.propValueDateFormatted = this.moment(theValue).format(this.propertyDateFormat);
                        break;
                    case ['file'].includes(this.getFormFieldType()):
                        let currentInput = this.propValueFileSelect[this.propValueFileSelect.length - 1];
                        if (currentInput && Array.isArray(currentInput)) {
                            currentInput.forEach((file) => {
                                let theFile = this.lodash.findIndex(this.propValueFile, (v) => v.name == file.name && v.size == file.size);
                                if (theFile < 0) {
                                    this.propValueFile.push(file);
                                }
                            });
                            theValue = this.propValueFile;
                            this.$refs['attachments'].reset();
                        }
                        else {
                            let fabrizio = [];
                            fabrizio.push(currentInput);
                            theValue = fabrizio;
                            this.propValueFile = theValue;
                        }
                        break;
                    case ['array_number'].includes(this.getFormFieldType()):
                        theValue = this.propValueArrayNumber;
                        break;
                    case ['array_text'].includes(this.getFormFieldType()):
                        if (this.propertyComputed.font_case != undefined && this.propertyComputed.font_case > -1) {
                            this.lodash.forEach(this.propValueArrayText, (entry, index) => {
                                switch (this.propertyComputed.font_case) {
                                    case FontCaseEnum.UPPERCASE:
                                        this.propValueArrayText[index] = entry.toUpperCase();
                                        break;
                                    case FontCaseEnum.LOWERCASE:
                                        this.propValueArrayText[index] = entry.toLowerCase();
                                        break;
                                }
                            });
                        }
                        theValue = this.propValueArrayText;
                        break;
                    case ['select'].includes(this.getFormFieldType()):
                        if (this.propValueSelect) {
                            theValue = this.lodash.find(this.getSelectOptions(), (so) => so.value == this.propValueSelect.value);
                        }
                        break;
                    case ['select_data_source'].includes(this.getFormFieldType()):
                        if (this.propValueSelectDataSource) {
                            theValue = this.propValueSelectDataSource.value;
                            // theValue = this.lodash.find(this.getSelectDataSourcePayloads(), (so: { value: any }) => so.value == this.propValueSelectDataSource.value);
                        }
                        break;
                    case ['boolean'].includes(this.getFormFieldType()):
                        if (this.propValue === undefined || this.propValue === "") {
                            this.propValue = "" + this.propertyComputed.default_value.default;
                        }
                        else {
                            theValue = this.propValue;
                        }
                        break;
                    case ['int'].includes(this.getFormFieldType()):
                        if (this.propValue && this.propValue.toString().trim().length) {
                            theValue = parseInt(this.propValue);
                        }
                        break;
                    case ['numeric'].includes(this.getFormFieldType()):
                        if (this.propValue && this.propValue.trim().length) {
                            theValue = parseFloat(this.propValue.replace(',', '.'));
                        }
                        break;
                    case ['multi_checkbox'].includes(this.getFormFieldType()):
                        theValue = this.propValueMultiCheck;
                        break;
                    case ['slider'].includes(this.getFormFieldType()):
                        theValue = this.propValue;
                        let theSliderValueSelected = this.lodash.find(this.propertyComputed.default_value.items, (v) => v.value === theValue);
                        if (theSliderValueSelected) {
                            this.sliderColor = theSliderValueSelected.color.trim() != "" ? theSliderValueSelected.color : "#0069D9";
                            this.sliderTooltipVisible = theSliderValueSelected.key.trim() != "" ? "active" : "none";
                            this.sliderTooltipPosition = "top";
                            if (parseInt(theSliderValueSelected.value) == this.propertyComputed.default_value.items[0].value) {
                                this.sliderTooltipPosition = "right";
                            }
                            if (parseInt(theSliderValueSelected.value) == this.propertyComputed.default_value.items[this.propertyComputed.default_value.items.length - 1].value) {
                                this.sliderTooltipPosition = "left";
                            }
                            this.selectedSliderValue = theSliderValueSelected.value;
                        }
                        break;
                    default:
                        if (this.propertyComputed.font_case != undefined && this.propertyComputed.font_case > -1) {
                            switch (this.propertyComputed.font_case) {
                                case FontCaseEnum.UPPERCASE:
                                    this.propValue = this.propValue.toUpperCase();
                                    break;
                                case FontCaseEnum.LOWERCASE:
                                    this.propValue = this.propValue.toLowerCase();
                                    break;
                            }
                        }
                        theValue = this.propValue;
                        break;
                }
            }
            else {
                theValue = this.propValue;
            }
            if (!['file'].includes(this.getFormFieldType()) || !!theValue && ['file'].includes(this.getFormFieldType())) {
                this.$emit("propertyIn", {
                    code: this.field.value,
                    value: theValue,
                    type: this.field.type,
                    input_type: theInputType,
                    size_exceeded: this.size_exceeded
                });
            }
        }
    },
    computed: {
        propertyDateFormat() {
            let format = this.propertyComputed?.default_value?.format;
            let separatore = this.propertyComputed?.default_value?.separator;
            return format.split("-").join(separatore).toUpperCase();
        },
        filePlaceholderComputed() {
            let fileSize = "";
            let fileNum = this.localizationService.PropertyLoc('carica_file');
            if (this.elencoData && this.elencoData.max_size && this.elencoData.max_size.value > 0) {
                fileSize = "(" + this.localizationService.PropertyLoc('dimensione_massima') + ": " + this.prettyBytes(this.elencoData.max_size.value * 1024) + ")";
            }
            if (this.propertyComputed && this.propertyComputed.default_value && this.propertyComputed.default_value.multiple) {
                fileNum = this.localizationService.PropertyLoc('carica_files');
            }
            return fileNum + ". " + fileSize;
        },
        propertyComputed() {
            return this.property
                ? this.property
                : {
                    property_description: this.field.title,
                    default_value: {
                        input_type: this.field.value
                    }
                };
        },
        userInfosValue() {
            let theValue = "";
            if (this.userInfos && this.userInfos[this.field.mapping]) {
                theValue = this.userInfos[this.field.mapping].value || this.userInfos[this.field.mapping];
            }
            return theValue;
        }
    },
    mounted() {
        this.currentDate = Date.now();
        if (this.propertyComputed && this.propertyComputed.default_value && this.propertyComputed.default_value.input_type) {
            switch (this.propertyComputed.default_value.input_type) {
                case "file":
                    let validationOptions = this.propertyComputed.default_value.elementsType?.validationOptions.filter((n) => n);
                    let code = this.propertyComputed.default_value.elementsType;
                    this.elencoData = this.lodash.find(validationOptions, (option) => option.code === code);
                    break;
                case "array_number":
                    var options = this.userInfosValue;
                    if (options && Array.isArray(options)) {
                        this.lodash.forEach(options, (o) => {
                            this.propValueArrayNumber.push(parseInt(o));
                            this.validationStatesArrayNumber.push(null);
                            this.validationErrorsArrayNumber.push(-1);
                        });
                    }
                    for (let i = 0; i < this.propertyComputed.default_value.nelementsmin; i++) {
                        this.propValueArrayNumber.push(this.propertyComputed.default_value.min ?? 0);
                        this.validationStatesArrayNumber.push(null);
                        this.validationErrorsArrayNumber.push(-1);
                    }
                    this.propertyIn();
                    break;
                case "array_text":
                    // let elementsType = this.propertyComputed.default_value.elementsType;
                    // let theElencoData = this.lodash.find(this.propertyComputed.default_value.elementsType?.validationOptions, (vo: any) => vo.code == elementsType);
                    // if (theElencoData) {
                    //   this.elencoData = theElencoData;
                    // }
                    var options = this.userInfosValue;
                    if (options && Array.isArray(options)) {
                        this.lodash.forEach(options, (o) => {
                            this.propValueArrayText.push(o);
                            this.validationStatesArrayText.push(null);
                            this.validationErrorsArrayText.push("");
                        });
                    }
                    for (let i = 0; i < this.propertyComputed.default_value.nelementsmin; i++) {
                        this.propValueArrayText.push("");
                        this.validationStatesArrayText.push(null);
                        this.validationErrorsArrayText.push("");
                    }
                    this.propertyIn();
                    break;
                case "select":
                    if (this.userInfosValue) {
                        this.propValue = this.userInfosValue;
                        this.propValueSelect = this.lodash.find(this.getSelectOptions(), (so) => so.value == this.userInfosValue);
                        this.propertyIn();
                    }
                    break;
                case "select_data_source":
                    this.propertyIn();
                    // if (this.userInfosValue) {
                    //   this.propValueSelectDataSource = this.lodash.find(this.getSelectDataSourcePayloads(), (so: { value: any }) => so.value == this.userInfosValue);
                    //   this.propertyIn();
                    // }
                    // let that = this;
                    // globalEventBus.$on('restSelectDataSourcePayloads', (dati: any) => {
                    //   this.fillRestPayloads(dati);
                    // });
                    break;
                case "boolean":
                case "multi_checkbox":
                    if (this.userInfosValue) {
                        this.propValueMultiCheck = JSON.parse(this.userInfosValue);
                    }
                    else {
                        this.propertyComputed.default_value.checkboxes?.forEach((c) => this.propValueMultiCheck[c.value] = !!c.state && (c.state == "true" || c.state == true));
                    }
                    this.propertyIn();
                    break;
                default:
                    if (this.userInfosValue) {
                        this.propValue = this.userInfosValue;
                        this.propertyIn();
                    }
            }
        }
        this.$emit("propertyStateChanged", this.getFormFieldRequired() ? false : null, this.field.value);
        globalEventBus.$on('validatePlease', () => {
            this.submited = true;
            this.updateThisState();
        });
    },
    beforeDestroy() {
        this.$emit("propertyDestroyed", this.field);
    },
    watch: {
        thisState() {
            this.$emit("propertyStateChanged", Boolean(this.thisState), this.field.value);
        },
        parentIndex() {
            this.$emit("propertyStateChanged", Boolean(this.thisState), this.field.value);
        }
    }
});
